
 .tab-item {
    background: #F8F8F8;
    border-radius: 0.5rem;
    width: 100%;
    height: 5.6rem;
    display: flex;
    justify-content: space-between;
    padding-left: 1.6rem;
    padding-right: 2.1rem;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0.8rem;
}

 .tab-item.active ~ .tab-item-info {
   display: block;
}

 .tab-item span {
    font-style: normal;
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2.1rem;
    color: #000000;
}

 .tab-item.active img {
    transform: rotate(180deg);
}

 .tab-item:last-child {
    margin-bottom: 0;
}

 .tab-item img {
    width: 1.4rem;
    height: 1.4rem;
}

 .tab-item-info p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.8rem;
    color: #000000;
    margin-top: 1.6rem;
}

 .tab-item-info .credit {
   margin-top: 1.6rem;
   margin-bottom: 3.2rem;
}

 .tab-item-info .credit span {
    color:#CD0000;
}

 .tab-item-info ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 3rem;
}
 .tab-item-info ul {
     margin-bottom: 2.2rem;
}

 .tab-item-info {
    display: none;
}

  .tab-item-info.active {
    display: block;
}

 .info-car-svg svg {
    transform: rotate(270deg);
    width: 37.746rem;
    height: 50.5rem;
}

 .info-car-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37.746rem;
    margin: 0 auto;
    margin-top: -3.5rem;
}

 .tab-group .colors {
    margin: 0 auto;
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}

 .tab-group .colors .value {
   width: 1.2rem;
   height: 1.2rem;
   border-radius: 100%;
   background: #F8F8F8;
   display: block;
   margin-right: 0.4rem;
}

 .tab-group .colors .value:last-child {
    margin-right: 0;
}

 .tab-group .colors .value.orijinal {
    background: #5CB85C;
}
 
 .tab-group .colors .value.rengli {
    background: #D9534F;
}

 .tab-group .color-item {
    text-align: center;
    margin-right: 8.3rem;
}

 .tab-group .colors .value.deyisdirilib {
    background: #F0AD4E;
}

 .tab-group .colors {
    display: flex;
}

 .tab-group .color-item:last-child {
    margin-right: 0;
}

 .tab-group .colors-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -7rem;
}

 .tab-group .color-item span {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: #000000;
    margin-top: 0.8rem;
    display: block;
}

 .color-tags-container {
    margin-top: 3.2rem;
}

 .color-tags-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

 .color-tags-container ul li {
    width: auto;
    height: 4.1rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
    margin-right: 1rem;
    margin-bottom: 1rem;
    border-radius: 3rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.7rem;
    color: #151515;
}

 .color-tags-container ul li:last-child {
    margin-right: 0;
}

 .color-tags-container ul li.orijinal {
    background: rgba(92, 184, 92, 0.3);
    border: 0.1rem solid rgba(92, 184, 92, 0.3);
}

 .color-tags-container ul li.deyisdirilib {
    background: rgba(217, 83, 79, 0.3);
    border: 0.1rem solid rgba(217, 83, 79, 0.3);
    
}

 .color-tags-container ul li.rengli {
    background: rgba(240, 173, 78, 0.3);
    border: 0.1rem solid rgba(240, 173, 78, 0.3);
}

.product-info-container .tags-ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}


.product-info-container .tags-ul .tag-li {
    height: 3.6rem;
    left: 12rem;
    top: 69.5rem;
    background: #F8F8F8;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #000000;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.product-info-container .tags-ul .tag-li:last-child {
    margin-right: 0;
}

.product-info-container .tags-ul .tag-li img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.product-info-container .product-tags {
    margin-top: 2.4rem;
}
