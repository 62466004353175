.flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
/*-----------Image Upload-------------*/
 
.price-request-container .anket-body .image-upload-container .image-upload-content .image-container img {
    width: 100%;
    height: 100%;
}

.file-img {
    width: 22.7rem;
    height: 13.9rem;
    background: #FFFFFF;
    border: 0.1rem solid rgba(9, 11, 77, 0.06);
    box-sizing: border-box;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


.image-upload.preview {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
}

.image-upload.preview-multiple{
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    top: 0;
}

.image-upload.preview-multiple .image-upload-single{
    margin-top: 16px;
    width: 22.7rem;
    height: 13.9rem;
}

.multiple-container{
    position: relative;
    height: max-content;
}

.image-upload.preview img {
    z-index: 9;
    background: white;
    float: left;
    position: absolute;
    width: 100%;
    height: 13.9rem;
}

.file-img .image-upload-single {
    width: 100%!important;
    height: 100%!important;
}

.file-img .image-upload-single .upload-container{
    width: 100%;
    height: 100%;
}

.image-container{
    width: 100%;
    height: 100%;
}

.image-container img{
    width: 100%;
    height: 100%;
}

.file-img.multiple label {
    background-image: none;
}

.file-img.multiple .upload-icon {
    position: absolute;
    z-index: 1;
    top: 41%;
    left: 39%;
    transform: translate(-30%, -30%);
}

.file-img .upload-icon {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.file-img .upload-icon img {
    width: 4rem;
    height: 4rem;
}

.multiple .upload-icon img {
    margin-right: -11px;
}

.file-img.multiple {
   width: 100%;
   height: 10.2rem;
   margin-top: 1.6rem;
}

.file-img.multiple p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2rem;
    color: #000;
}

.file-img.first label {
    background-image: url(../../uploads/front-car.svg);
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    width: 12.4rem;
    height: 10.6rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;
}

.file-img.second label {
    background-image: url(../../uploads/salon-car.png);
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    width: 12.4rem;
    height: 10.6rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;
}

.file-img.third label {
    background-image: url(../../uploads/bac-car.png);
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    width: 12.4rem;
    height: 10.6rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;
}

.file-img.fourth label {
    background-image: url(../../uploads/right-car.png);
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    width: 15.4rem;
    height: 10.6rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    position: relative;
}

.file-img input {
    display: none;
}
 
.price-request-container .anket-body .image-upload-container .image-upload-content .image-container img {
    border-radius: 0.5rem;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
}

.price-request-container .anket-body .image-upload-container .image-upload-content .image-container {
    width: 22.7rem;
    height: 13.9rem;
    
}

.image-upload-content .form-group .form-control{
    display: none;
}

.delete{
    cursor: pointer;
    width: 30px!important;
    height: 30px!important;
    position: absolute;
    top: 5px;
    z-index: 99;
    right: 5px;
}

.image-upload.preview-multiple .delete{
    top: 21px;
}