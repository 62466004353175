.calendar__configuration *{
    font-size: 24px;
}

.config__firstTab, .config__secondTab{
    width: 35rem;
}



.config__firstTab div, .config__secondTab div{
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.config__firstTab div input{
    width: 12em;
}

.config__secondTab button{
    margin-top: 20px;
    float: right;
    width: 11em;
}

select{
    width: 12em;
}
