html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,p,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
    background: #FAFBF9 !important;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

header{
    padding:26px 40px 20px 40px; 
    box-sizing: border-box;
}

.head_title{
    box-sizing: border-box; 
    height: 38px;
    font-size: 36px;
line-height: 42px;
font-family: 'Roboto', sans-serif;
}

.user_profile{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #C4C4C4;
    color: #000000;
    margin-left: 34px;
}
.user_profile span{
    font-weight: 600;
}

.user_profile:hover{
    cursor: pointer;
}

.notifications:hover{
    cursor: pointer;
}

.search{
width: 300px;
height: 38px;
padding: 6px 21px 6px 32px; 
color: rgba(0, 0, 0, 0.5);
border: 1px solid rgba(0, 0, 0, 0.3);
box-sizing: border-box;
border-radius: 24px;
background: #FAFBF9 !important;
}
.search:hover{
    
    cursor: pointer;
}

.dashboard-container{
    margin: 0 40px;
}

.nav-menu li{
width: 115px;
height: 57px;
border-radius: 8px;
border: 1px solid gray;
text-align: center;
margin:3px 20px 30px 20px;
padding: 9px 0px;
}

li.active_nav{
color: white;
background: #C9202F;
border: 1px solid #C9202F !important;
}

.nav-menu li:hover{
    color: white;
    background: #C9202F;
    transition: .3s linear;
    border: 1px solid #C9202F;
    
    cursor: pointer;
}

.nav-menu p{
    font-size: 12px;
}

.title{
    font-size: 32px;
    font-weight: 700;
}

thead{
    background-color: white;
}

.table{
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.nav-small{
    margin: 38px 0 30px;
}
.nav-small label{
    margin: 0 38px 20px 0;
}
/* .nav-small input{
    width: 100px;
} */
.nav-small li{
    
border: 1px solid gray;
border-radius: 4px; 
padding: 0px 12px !important;
margin-right: 30px;
}
.nav-small li:hover{
    color: white;
    background: #C9202F;
    transition: .3s linear;
    border: 1px solid #C9202F;
    cursor: pointer;
}

.user_details{
    background: #E5E5E5;
    padding: 20px;
border-radius: 8px;
}



.user_contact  label{
    font-weight: 700;
    margin-right: 10px;
    }
    
.user_contact  li{

     margin-right: 30px;
}

.user_contact  li span{

    background-color: white;
    border-radius: 3px;
}

.user_contact{
    margin: 20px 0 0 0;
}

.bootstrap_modal_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 20px;
    font-size: 18px;
    width: 145px;
    height: 43px;
    border-radius: 8px;
    border: none;
    font-weight: 600;
}

.confirm_modal_button{  
    color: white;
    background: #D03125;

}

.close_modal{
    background-color: white;
    color: #D03125; 
}

.modal-body{
    padding: 25px;
}
.modal-body ul{
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}
.modal-body ul li{
    display: flex;
    align-items: center;
    font-size: 16px;
}
.modal-body ul li p{
   margin: 0;
   max-width: 300px;
   height: 25px;
   overflow: hidden;
   text-overflow: ellipsis;
   resize: horizontal;
}
.modal-body ul label{
    font-weight: 700;
    margin-right: 10px;
}

.modal-body span{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 28px;
width: 139px;
padding: 4px, 8px, 4px, 8px;

   
}
.randevu_date {
    justify-content: center;
}
.randevu_date span{
    margin: 10px 20px;
}

.modal-footer{
    margin: 10px;
}

.umumi__veziyyet input{
    width: 36.5rem;
    height: 40px;
}



#elan_basligi{
    margin-right: 1rem;
}

.orijinal{
    background-color: rgba(92, 184, 92, 0.3);
}

.rengli{
    background-color: rgba(240, 173, 78, 0.3);
}

.deyisdirilib{
    background-color:rgba(217, 83, 79, 0.3); 
}

.without_ampm::-webkit-datetime-edit-ampm-field {
    display: none;
  }
  input[type=time]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
    margin: -10px; 
  }
  .MuiFormControl-root{
    margin: 0!important;
  }