.fa-user-cog{
    cursor: pointer;
}

#modal--content{
    background-color: #e5e5e5;
    margin: auto;
    width: 25rem;
}

.popup__customer__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}

.popup__customer__info label, .popup__indisat__info #info--title{
    font-weight: 700;
}

.popup__indisat__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.indisat__info--car{
    margin-left: 3em;
}

.popup__calendar{
    margin: auto;
    display: flex;
    justify-content: space-around;
}

.calendar__date, .calendar__time{
    display: flex;
    flex-direction: column;
}

.calendar__date input{
    width: 6rem;
}

.calendar__time input{
    width: 3rem;
}

.modal-footer{
    margin: auto;
}




#accept{
    background-color: #d03125;
    color: #ffffff;
}

#cancel{
    background-color: #ffffff;
    color: #d03125;
}