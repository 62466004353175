/*--------------Product info-----------------*/

.product-info-container .info-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-info-container .info-top .product-name {
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.9rem;
    color: #000000;
}

.product-info-container .info-top .product-price {
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #FFFFFF;
    position: relative;
} 

.product-info-container .info-top .product-price p {
    position: absolute;
    top: 1rem;
    right: 0.8rem;
    margin: 0;
    font-size: 1.5rem;
}

.product-info-container .info-top .product-price p > .currency-span {
    font-size: 1.4rem;
     margin-left: 0.5rem;
}

.product-info-container .info-top .product-price img {
    width: 12.9rem;
    height: 4rem;
}

.product-info-container {
    margin-top: 3.3rem;
}

.product-info-container .tags-ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.product-info-container .tags-ul .tag-li {
    height: 3.6rem;
    left: 12rem;
    top: 69.5rem;
    background: #F8F8F8;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.7rem;
    color: #000000;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.product-info-container .tags-ul .tag-li:last-child {
    margin-right: 0;
}

.product-info-container .tags-ul .tag-li img {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
}

.product-info-container .product-tags {
    margin-top: 2.4rem;
}


.all__images{
    width: 1000px;
    display:flex;
    flex-wrap: wrap;
}

.all__images .all__images--image{
    height: 150px;
    width: 200px;
    background-color: red;
    margin: 5px;

}

.customer__info{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
}

.customer__info input{
    border-radius: 10px;
    margin-left: 5px;
    background-color: white;
}

.customer__info label{
    line-height: 1.9rem;
}

.common__div{
    margin-left: 5rem;
}

.operation__buttons button{
    width: 7rem;
}

.form-check{
    border: 1px solid black;
    width: 164px;
}

.form-check .form-check-input{
    margin-left: -0.9em;
}


.namee{
    height: 3.6rem;
    left: 12rem;
    top: 69.5rem;
    background: #F8F8F8;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: #000000;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.prName__anchor{
    color: inherit;
    text-decoration: none;
}


.elanSelect{
    border:0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin-right:0 !important;

}


.elanSelect:hover{
 background: none !important;
}